import { Pag, initialPag } from '@resources/pagination/_pag';

// --
export interface CardsPag {
    cards: Pag;
    visits: Pag;
}

export const initialCardsPag: CardsPag = {
    cards: { ...initialPag },
    visits: { ...initialPag },
};

// --
export interface ReportsPag {
    action_logs: Pag;
}

export const initialReportsPag: ReportsPag = {
    action_logs: { ...initialPag },
};

// --
export interface State {
    cards: CardsPag;
    reports: ReportsPag;
}

export const initialState: State = {
    cards: { ...initialCardsPag },
    reports: { ...initialReportsPag },
};
