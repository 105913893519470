import { applyArrayDefault, mergeArrays } from '@resources/helpers/array';
import { ActionPayload } from '@resources/helpers/redux';
import { State, initialState } from '@resources/reports/_state';
import { REPORTS_CONST } from '@resources/reports/constants';
import { ActionLogsResp, GraphIncomeResp } from '@resources/reports/interfaces';
import { initialActionLog } from '@resources/reports/_action-log';
import { initialGraphIncome } from '@resources/reports/_graph';

const actionLogsSuccess = (state: State, action: ActionPayload<ActionLogsResp>): State => ({
    ...state,
    action_logs: mergeArrays(
        state.action_logs,
        applyArrayDefault(action.payload.action_logs, initialActionLog),
    ),
    action_logs_ids: action.payload.action_logs.map((actionLog) => actionLog.id),
});

const graphIncomeSuccess = (state: State, action: ActionPayload<GraphIncomeResp>): State => ({
    ...state,
    graph_offices_incomes: action.payload.graph_offices_incomes.map((officeIncome) => ({
        ...initialGraphIncome,
        ...officeIncome,
    })),
});

export const reportsReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    switch (action.type) {
        case REPORTS_CONST.REPORTS_ACTION_LOGS_SUCCESS:
            return actionLogsSuccess(state, action);

        case REPORTS_CONST.REPORTS_GRAPH_OFFICES_INCOMES_SUCCESS:
            return graphIncomeSuccess(state, action);

        default:
            return state;
    }
};
