import moment from 'moment-timezone';

export interface ActionLog {
    id: number;
    user_id: number;
    name: string;
    data_before: any;
    data_after: any;
    created_at: string;
}

export const initialActionLog: ActionLog = {
    id: 0,
    user_id: 0,
    name: '',
    data_before: {},
    data_after: {},
    created_at: '',
};
