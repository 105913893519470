import { Request, RequestResponse } from '@resources/helpers/request';
import {
    OrgsResp,
    OrgCreateReq,
    OrgUpdateReq,
    OrgUpdateResp,
    OrgRemoveReq,
    OrgRemoveResp,
} from '@resources/orgs/interfaces';

export function repoOrgs(): Promise<OrgsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/orgs/')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoOrgCreate(payload: OrgCreateReq): Promise<OrgsResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/orgs/', payload.org)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoOrgUpdate(payload: OrgUpdateReq): Promise<OrgUpdateResp> {
    return new Promise((resolve, reject) => {
        Request.put('/v1/orgs/' + payload.org.id, payload.org)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoOrgRemove(payload: OrgRemoveReq): Promise<OrgRemoveResp> {
    return new Promise((resolve, reject) => {
        Request.remove('/v1/orgs/' + payload.org_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
