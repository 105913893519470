export const ORGS_CONST = {
    // --
    ORGS_ORGS_FETCH: 'ORGS_ORGS_FETCH',
    ORGS_ORGS_SUCCESS: 'ORGS_ORGS_SUCCESS',
    ORGS_ORGS_FAIL: 'ORGS_ORGS_FAIL',

    // --
    ORGS_ORG_CREATE_FETCH: 'ORGS_ORG_CREATE_FETCH',
    ORGS_ORG_CREATE_SUCCESS: 'ORGS_ORG_CREATE_SUCCESS',
    ORGS_ORG_CREATE_FAIL: 'ORGS_ORG_CREATE_FAIL',

    // --
    ORGS_ORG_UPDATE_FETCH: 'ORGS_ORG_UPDATE_FETCH',
    ORGS_ORG_UPDATE_SUCCESS: 'ORGS_ORG_UPDATE_SUCCESS',
    ORGS_ORG_UPDATE_FAIL: 'ORGS_ORG_UPDATE_FAIL',

    // --
    ORGS_ORG_REMOVE_FETCH: 'ORGS_ORG_REMOVE_FETCH',
    ORGS_ORG_REMOVE_SUCCESS: 'ORGS_ORG_REMOVE_SUCCESS',
    ORGS_ORG_REMOVE_FAIL: 'ORGS_ORG_REMOVE_FAIL',
};
