import { Request, RequestResponse } from '@resources/helpers/request';
import { ConfigResp, ConfigUpdateReq, ConfigUpdateResp } from './interfaces';

export function repoConfig(): Promise<ConfigResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/config/')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoConfigUpdate(payload: ConfigUpdateReq): Promise<ConfigUpdateResp> {
    return new Promise((resolve, reject) => {
        Request.put('/v1/config/', payload.config)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
