export interface Document {
    uuid: string;
    visit_id: number;
    created_at: string;
}

export const initialDocument: Document = {
    uuid: '',
    visit_id: 0,
    created_at: '',
};
