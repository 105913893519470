import axios, { AxiosRequestConfig, Method, AxiosBasicCredentials } from 'axios';
import cookie from 'js-cookie';

import { ErrorResponse } from '@resources/helpers/error';

export interface RequestResponse<T> {
    message: string;
    content: T;
}

export interface Options {
    method: Method;
    query?: any;
    body?: any;
    headers?: any;
    multipart?: boolean;
    host?: string;
    auth?: AxiosBasicCredentials;
}

export enum HTTP_STATUS {
    OK = 200,
    NOT_FOUND = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    CREATED = 201,
    NOT_CONTENT = 204,
}

// Преобразует объект в URL query
export function buildQuery(object: any) {
    let params: Array<string> = [];
    for (let key in object) {
        params.push(key + '=' + object[key]);
    }
    return params.length !== 0 ? '?' + params.join('&') : '';
}

function serialize(body: any) {
    var formBody = [];
    for (var property in body) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
}

export const getAccessToken = () => cookie.get('access_token');

function request(path: string, options: Options) {
    let url = process.env.HOST_API + path + buildQuery(options.query);

    if (options.host) {
        url = options.host + path + buildQuery(options.query);
    }

    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        url: url,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Accept-Encoding': 'gzip, deflate',
            Authorization: accessToken ? 'Bearer ' + accessToken : '',
            ...options.headers,
        },
        method: options.method,
        data: serialize(options.body),
        validateStatus: () => true,
        auth: options.auth,
    };

    return axios
        .request(config)
        .then((response) => {
            if (
                response.status === HTTP_STATUS.OK ||
                response.status === HTTP_STATUS.CREATED ||
                response.status === HTTP_STATUS.NOT_CONTENT
            ) {
                return response.data;
            } else {
                throw new ErrorResponse(response.data.msg, response.status);
            }
        })
        .catch((e: ErrorResponse) => Promise.reject(new ErrorResponse(e.message, e.http_status)));
}

// Создание
function post(path: string, body: any = {}, query: any = {}) {
    return request(path, { method: 'post', query: query, body: body });
}

// Получение
function get(path: string, query: any = {}) {
    return request(path, { method: 'get', query: query });
}

// Обновление
function put(path: string, body: any = {}, query: any = {}) {
    return request(path, { method: 'put', query: query, body: body });
}

// Удаление
function remove(path: string, query: any = {}) {
    return request(path, { method: 'delete', query: query, body: {} });
}

export const Request = { post, get, put, remove, request };
