export interface VisitPrice {
    id: number;
    visit_id: number;
    name: string;
    name_short: string;
    price: number;
}

export const initialVisitPrice: VisitPrice = {
    id: 0,
    visit_id: 0,
    name: '',
    name_short: '',
    price: 0,
};
