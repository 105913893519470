import { combineReducers } from 'redux';

import { State as CardsState } from '@resources/cards/_state';
import { State as UsersState } from '@resources/users/_state';
import { State as PaginationState } from '@resources/pagination/_state';
import { State as ReportsState } from '@resources/reports/_state';
import { State as PricesState } from '@resources/prices/_state';
import { State as ActionsState } from '@resources/actions/_state';
import { State as ConfigState } from '@resources/config/_state';
import { State as FiltersState } from '@resources/filters/_state';
import { State as OrgsState } from '@resources/orgs/_state';
import { State as OfficesState } from '@resources/offices/_state';

import { cardsReducer } from '@resources/cards/reducer';
import { usersReducer } from '@resources/users/reducer';
import { paginationReducer } from '@resources/pagination/reducer';
import { reportsReducer } from '@resources/reports/reducer';
import { pricesReducer } from '@resources/prices/reducer';
import { actionsReducer } from '@resources/actions/reducer';
import { configReducer } from '@resources/config/reducer';
import { filtersReducer } from '@resources/filters/reducer';
import { orgsReducer } from '@resources/orgs/reducer';
import { officesReducer } from '@resources/offices/reducer';

export type GlobalState = {
    cards: CardsState;
    users: UsersState;
    pagination: PaginationState;
    reports: ReportsState;
    prices: PricesState;
    actions: ActionsState;
    config: ConfigState;
    filters: FiltersState;
    orgs: OrgsState;
    offices: OfficesState;
};

export const reducers = combineReducers({
    prices: pricesReducer,
    cards: cardsReducer,
    users: usersReducer,
    pagination: paginationReducer,
    reports: reportsReducer,
    actions: actionsReducer,
    config: configReducer,
    filters: filtersReducer,
    orgs: orgsReducer,
    offices: officesReducer,
});
