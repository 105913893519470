export interface VisitCard {
    id: number;
    unique_id: string;
    first_name: string;
    last_name: string;
    patronymic: string;
}

export const initialVisitCard: VisitCard = {
    id: 0,
    unique_id: '',

    first_name: '',
    last_name: '',
    patronymic: '',
};
