export const CARDS_CONST = {
    CARDS_CARDS_FETCH: 'CARDS_CARDS_FETCH',
    CARDS_CARDS_SUCCESS: 'CARDS_CARDS_SUCCESS',
    CARDS_CARDS_FAIL: 'CARDS_CARDS_FAIL',

    // --
    CARDS_CARD_CREATE_FETCH: 'CARDS_CARD_CREATE_FETCH',
    CARDS_CARD_CREATE_SUCCESS: 'CARDS_CARD_CREATE_SUCCESS',
    CARDS_CARD_CREATE_FAIL: 'CARDS_CARD_CREATE_FAIL',

    // --
    CARDS_CARD_UPDATE_FETCH: 'CARDS_CARD_UPDATE_FETCH',
    CARDS_CARD_UPDATE_SUCCESS: 'CARDS_CARD_UPDATE_SUCCESS',
    CARDS_CARD_UPDATE_FAIL: 'CARDS_CARD_UPDATE_FAIL',

    // --
    CARDS_CARD_REMOVE_FETCH: 'CARDS_CARD_REMOVE_FETCH',
    CARDS_CARD_REMOVE_SUCCESS: 'CARDS_CARD_REMOVE_SUCCESS',
    CARDS_CARD_REMOVE_FAIL: 'CARDS_CARD_REMOVE_FAIL',

    // --
    CARDS_VISIT_SMS_SIGN_FETCH: 'CARDS_VISIT_SMS_SIGN_FETCH',
    CARDS_VISIT_SMS_SIGN_SUCCESS: 'CARDS_VISIT_SMS_SIGN_SUCCESS',
    CARDS_VISIT_SMS_SIGN_FAIL: 'CARDS_VISIT_SMS_SIGN_FAIL',

    // --
    CARDS_VISITS_FETCH: 'CARDS_VISITS_FETCH',
    CARDS_VISITS_SUCCESS: 'CARDS_VISITS_SUCCESS',
    CARDS_VISITS_FAIL: 'CARDS_VISITS_FAIL',

    // --
    CARDS_CARDS_SUGGESTS_FETCH: 'CARDS_CARDS_SUGGESTS_FETCH',
    CARDS_CARDS_SUGGESTS_SUCCESS: 'CARDS_CARDS_SUGGESTS_SUCCESS',
    CARDS_CARDS_SUGGESTS_FAIL: 'CARDS_CARDS_SUGGESTS_FAIL',

    // --
    CARDS_ADDRESS_SUGGESTS_FETCH: 'CARDS_ADDRESS_SUGGESTS_FETCH',
    CARDS_ADDRESS_SUGGESTS_SUCCESS: 'CARDS_ADDRESS_SUGGESTS_SUCCESS',
    CARDS_ADDRESS_SUGGESTS_FAIL: 'CARDS_ADDRESS_SUGGESTS_FAIL',

    // --
    CARDS_CARD_FETCH: 'CARDS_CARD_FETCH',
    CARDS_CARD_SUCCESS: 'CARDS_CARD_SUCCESS',
    CARDS_CARD_FAIL: 'CARDS_CARD_FAIL',

    // --
    CARDS_ISSUED_SUGGESTS_FETCH: 'CARDS_ISSUED_SUGGESTS_FETCH',
    CARDS_ISSUED_SUGGESTS_SUCCESS: 'CARDS_ISSUED_SUGGESTS_SUCCESS',
    CARDS_ISSUED_SUGGESTS_FAIL: 'CARDS_ISSUED_SUGGESTS_FAIL',

    // --
    CARDS_VISITS_ORGS_FETCH: 'CARDS_VISITS_ORGS_FETCH',
    CARDS_VISITS_ORGS_SUCCESS: 'CARDS_VISITS_ORGS_SUCCESS',
    CARDS_VISITS_ORGS_FAIL: 'CARDS_VISITS_ORGS_FAIL',
};
