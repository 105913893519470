export class ErrorResponse extends Error {
    http_status: number = 0;
    msg: string = '';

    constructor(message: string, httpStatus: number) {
        super(message);

        if (message === 'Network Error') {
            message = 'Ошибка сети';
        }

        this.msg = message;
        this.http_status = httpStatus;
    }
}
