import { actionFlow } from '@resources/helpers/redux';
import { PRICES_CONST } from '@resources/prices/constants';
import { PriceCreatePayload, PriceRemovePayload, PriceUpdatePayload } from './interfaces';
import { repoPriceCreate, repoPriceRemove, repoPrices, repoPriceUpdate } from './repo';

export const prices = () => (dispatch) =>
    actionFlow(dispatch)(
        PRICES_CONST.PRICES_PRICES_FETCH,
        PRICES_CONST.PRICES_PRICES_SUCCESS,
        PRICES_CONST.PRICES_PRICES_FAIL,
        async () => await repoPrices(),
    );

export const priceCreate = (payload: PriceCreatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        PRICES_CONST.PRICES_PRICE_CREATE_FETCH,
        PRICES_CONST.PRICES_PRICE_CREATE_SUCCESS,
        PRICES_CONST.PRICES_PRICE_CREATE_FAIL,
        async () =>
            await repoPriceCreate({
                ...payload.price,
                price_docs: payload.price.docs.join(','),
            }),
    );

export const priceUpdate = (payload: PriceUpdatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        PRICES_CONST.PRICES_PRICE_UPDATE_FETCH,
        PRICES_CONST.PRICES_PRICE_UPDATE_SUCCESS,
        PRICES_CONST.PRICES_PRICE_UPDATE_FAIL,
        async () =>
            await repoPriceUpdate({
                ...payload.price,
                price_docs: payload.price.docs.join(','),
            }),
    );

export const priceRemove = (payload: PriceRemovePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        PRICES_CONST.PRICES_PRICE_REMOVE_FETCH,
        PRICES_CONST.PRICES_PRICE_REMOVE_SUCCESS,
        PRICES_CONST.PRICES_PRICE_REMOVE_FAIL,
        async () => {
            await repoPriceRemove(payload);
            return { price_id: payload.price_id };
        },
    );
