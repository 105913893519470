import { applyArrayDefault, updateArrayItem } from '@resources/helpers/array';
import { ActionPayload } from '@resources/helpers/redux';
import { State, initialState } from '@resources/offices/_state';
import { initialOffice } from '@resources/offices/_office';
import { OFFICES_CONST } from '@resources/offices/constants';
import { OfficeRemoveResp, OfficeSaveResp, OfficesResp } from '@resources/offices/interfaces';

const officesSuccess = (state: State, action: ActionPayload<OfficesResp>): State => ({
    ...state,
    offices: applyArrayDefault(action.payload.offices, initialOffice),
});

const officeSaveSuccess = (state: State, action: ActionPayload<OfficeSaveResp>): State => {
    const find = state.offices.find((office) => office.id === action.payload.office.id);
    return {
        ...state,
        offices: find
            ? updateArrayItem(state.offices, action.payload.office.id, (office) => ({
                  ...initialOffice,
                  ...office,
                  ...action.payload.office,
              }))
            : [
                  ...state.offices,
                  {
                      ...initialOffice,
                      ...action.payload.office,
                  },
              ],
    };
};

const officeRemoveSuccess = (state: State, action: ActionPayload<OfficeRemoveResp>): State => ({
    ...state,
    offices: state.offices.filter((office) => office.id !== action.payload.office_id),
});

export const officesReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    switch (action.type) {
        case OFFICES_CONST.OFFICES_OFFICES_SUCCESS:
            return officesSuccess(state, action);

        case OFFICES_CONST.OFFICES_OFFICE_SAVE_SUCCESS:
            return officeSaveSuccess(state, action);

        case OFFICES_CONST.OFFICES_OFFICE_REMOVE_SUCCESS:
            return officeRemoveSuccess(state, action);

        default:
            return state;
    }
};
