export enum DATE_PERIOD {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export const DATE_PERIOD_LABEL = {
    [DATE_PERIOD.DAY]: 'День',
    [DATE_PERIOD.WEEK]: 'Неделя',
    [DATE_PERIOD.MONTH]: 'Месяц',
    [DATE_PERIOD.YEAR]: 'Год',
};

export const DATE_PERIODS = [
    DATE_PERIOD.DAY,
    DATE_PERIOD.MONTH,
    DATE_PERIOD.YEAR,
];
