export const USERS_CONST = {
    // --
    USERS_AUTH_LOGOUT: 'USERS_AUTH_LOGOUT',

    // --
    USERS_AUTH_LOGIN_FETCH: 'USERS_AUTH_LOGIN_FETCH',
    USERS_AUTH_LOGIN_SUCCESS: 'USERS_AUTH_LOGIN_SUCCESS',
    USERS_AUTH_LOGIN_FAIL: 'USERS_AUTH_LOGIN_FAIL',

    // --
    USERS_USERS_FETCH: 'USERS_USERS_FETCH',
    USERS_USERS_SUCCESS: 'USERS_USERS_SUCCESS',
    USERS_USERS_FAIL: 'USERS_USERS_FAIL',

    // --
    USERS_USER_PROFILE_FETCH: 'USERS_USER_PROFILE_FETCH',
    USERS_USER_PROFILE_SUCCESS: 'USERS_USER_PROFILE_SUCCESS',
    USERS_USER_PROFILE_FAIL: 'USERS_USER_PROFILE_FAIL',

    // --
    USERS_USER_CREATE_FETCH: 'USERS_USER_CREATE_FETCH',
    USERS_USER_CREATE_SUCCESS: 'USERS_USER_CREATE_SUCCESS',
    USERS_USER_CREATE_FAIL: 'USERS_USER_CREATE_FAIL',

    // --
    USERS_USER_UPDATE_FETCH: 'USERS_USER_UPDATE_FETCH',
    USERS_USER_UPDATE_SUCCESS: 'USERS_USER_UPDATE_SUCCESS',
    USERS_USER_UPDATE_FAIL: 'USERS_USER_UPDATE_FAIL',

    // --
    USERS_USER_REMOVE_FETCH: 'USERS_USER_REMOVE_FETCH',
    USERS_USER_REMOVE_SUCCESS: 'USERS_USER_REMOVE_SUCCESS',
    USERS_USER_REMOVE_FAIL: 'USERS_USER_REMOVE_FAIL',

    // --
    USERS_LOGINS_FETCH: 'USERS_LOGINS_FETCH',
    USERS_LOGINS_SUCCESS: 'USERS_LOGINS_SUCCESS',
    USERS_LOGINS_FAIL: 'USERS_LOGINS_FAIL',

    // --
    USERS_AUTHORIZED_SET: 'USERS_AUTHORIZED_SET',
};
