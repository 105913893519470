import { debounce, call, put } from 'redux-saga/effects';
import { CARDS_CONST } from '@resources/cards/constants';
import { repoAddressSuggests, repoIssuedSuggests } from '@resources/cards/repo';
import {
    AddressSuggestsPayload,
    AddressSuggestsResp,
    IssuedSuggestsPayload,
    IssuedSuggestsResp,
} from '@resources/cards/interfaces';
import { actionCreate, ActionPayload } from '@resources/helpers/redux';

export function* cardsSaga() {
    // Задержка при запросе подсказок адреса
    yield debounce(
        200,
        CARDS_CONST.CARDS_ADDRESS_SUGGESTS_FETCH,
        function* (payload: ActionPayload<AddressSuggestsPayload>) {
            try {
                const resp: AddressSuggestsResp = yield call(repoAddressSuggests, {
                    query: payload.payload.address,
                });
                yield put(actionCreate(CARDS_CONST.CARDS_ADDRESS_SUGGESTS_SUCCESS, resp));
            } catch (e) {
                yield put(actionCreate(CARDS_CONST.CARDS_ADDRESS_SUGGESTS_FAIL, e));
            }
        },
    );

    // Задержка при запросе подсказок учреждения выдавщего документ
    yield debounce(
        200,
        CARDS_CONST.CARDS_ISSUED_SUGGESTS_FETCH,
        function* (payload: ActionPayload<IssuedSuggestsPayload>) {
            try {
                const resp: IssuedSuggestsResp = yield call(repoIssuedSuggests, {
                    q: payload.payload.q,
                });
                yield put(actionCreate(CARDS_CONST.CARDS_ISSUED_SUGGESTS_SUCCESS, resp));
            } catch (e) {
                yield put(actionCreate(CARDS_CONST.CARDS_ISSUED_SUGGESTS_FAIL, e));
            }
        },
    );
}
