import { Request, RequestResponse } from '@resources/helpers/request';
import {
    UserCreateReq,
    UserCreateResp,
    AuthLoginReq,
    AuthLoginResp,
    UserProfileResp,
    UserRemoveReq,
    UserRemoveResp,
    UserUpdateReq,
    UserUpdateResp,
    UsersResp,
    LoginsResp,
} from '@resources/users/interfaces';

export function repoAuthLogin(payload: AuthLoginReq): Promise<AuthLoginResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/auth/login', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoUsers(): Promise<UsersResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/users/')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoUserProfile(): Promise<UserProfileResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/profile')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoUserCreate(payload: UserCreateReq): Promise<UserCreateResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/users/', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoUserUpdate(payload: UserUpdateReq): Promise<UserUpdateResp> {
    return new Promise((resolve, reject) => {
        let path = '/v1/users/' + payload.user.id;

        if (payload.is_data) {
            path += '/data';
        }

        Request.put(path, payload.user)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoUserRemove(payload: UserRemoveReq): Promise<UserRemoveResp> {
    return new Promise((resolve, reject) => {
        Request.remove('/v1/users/' + payload.user_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoLogins(): Promise<LoginsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/users/logins')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
