import { actionFlow } from '@resources/helpers/redux';
import { OFFICES_CONST } from '@resources/offices/constants';
import { OfficeSavePayload, OfficeRemovePayload } from '@resources/offices/interfaces';
import { repoOffices, repoOfficeSave, repoOfficeRemove } from '@resources/offices/repo';

export const offices = () => (dispatch) =>
    actionFlow(dispatch)(
        OFFICES_CONST.OFFICES_OFFICES_FETCH,
        OFFICES_CONST.OFFICES_OFFICES_SUCCESS,
        OFFICES_CONST.OFFICES_OFFICES_FAIL,
        async () => await repoOffices(),
    );

export const officeSave = (payload: OfficeSavePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        OFFICES_CONST.OFFICES_OFFICE_SAVE_FETCH,
        OFFICES_CONST.OFFICES_OFFICE_SAVE_SUCCESS,
        OFFICES_CONST.OFFICES_OFFICE_SAVE_FAIL,
        async () => await repoOfficeSave(payload),
    );

export const officeRemove = (payload: OfficeRemovePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        OFFICES_CONST.OFFICES_OFFICE_REMOVE_FETCH,
        OFFICES_CONST.OFFICES_OFFICE_REMOVE_SUCCESS,
        OFFICES_CONST.OFFICES_OFFICE_REMOVE_FAIL,
        async () => {
            await repoOfficeRemove(payload);
            return { office_id: payload.office_id };
        },
    );
