// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-cards-tsx": () => import("./../../../src/pages/cards.tsx" /* webpackChunkName: "component---src-pages-cards-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orgs-tsx": () => import("./../../../src/pages/orgs.tsx" /* webpackChunkName: "component---src-pages-orgs-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */)
}

