import { ActionPayload } from '@resources/helpers/redux';

import { State, initialState } from '@resources/config/_state';
import { CONFIG_CONST } from '@resources/config/constants';
import { ConfigResp, ConfigUpdateResp } from '@resources/config/interfaces';
import { initialConfig } from '@resources/config/_config';

const configUpdate = (
    state: State,
    action: ActionPayload<ConfigResp | ConfigUpdateResp>,
): State => ({
    ...state,
    config: {
        ...initialConfig,
        ...action.payload.config,
    },
});

export const configReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    switch (action.type) {
        case CONFIG_CONST.CONFIG_CONFIG_SUCCESS:
        case CONFIG_CONST.CONFIG_CONFIG_UPDATE_SUCCESS:
            return configUpdate(state, action);
        default:
            return state;
    }
};
