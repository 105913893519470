import { Action } from 'redux';

import { State, initialState } from '@resources/filters/_state';
import { FilAction } from '@resources/filters/_fil';
import { actionName, actionNamespace, ActionPayload } from '@resources/helpers/redux';

export function filters(
    state: State,
    namespace: string,
    name: string,
    action: FilAction<any>,
): State {
    let stateLoad: { [key: string]: any } = state;
    return {
        ...state,
        [namespace]: {
            ...stateLoad[namespace],
            [name]: {
                ...stateLoad[namespace][name],
                ...action.filters,
            },
        },
    };
}

export const filtersReducer = (
    state: State = initialState,
    action: ActionPayload<FilAction<any>>,
): State => {
    const { type } = action;

    if (type.match('FETCH') && action.payload && action.payload.filters) {
        return filters(state, actionNamespace(type), actionName(type), action.payload);
    }

    return state;
};
