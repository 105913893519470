export const OFFICES_CONST = {
    // --
    OFFICES_OFFICES_FETCH: 'OFFICES_OFFICES_FETCH',
    OFFICES_OFFICES_SUCCESS: 'OFFICES_OFFICES_SUCCESS',
    OFFICES_OFFICES_FAIL: 'OFFICES_OFFICES_FAIL',

    // --
    OFFICES_OFFICE_SAVE_FETCH: 'OFFICES_OFFICE_SAVE_FETCH',
    OFFICES_OFFICE_SAVE_SUCCESS: 'OFFICES_OFFICE_SAVE_SUCCESS',
    OFFICES_OFFICE_SAVE_FAIL: 'OFFICES_OFFICE_SAVE_FAIL',

    // --
    OFFICES_OFFICE_REMOVE_FETCH: 'OFFICES_OFFICE_REMOVE_FETCH',
    OFFICES_OFFICE_REMOVE_SUCCESS: 'OFFICES_OFFICE_REMOVE_SUCCESS',
    OFFICES_OFFICE_REMOVE_FAIL: 'OFFICES_OFFICE_REMOVE_FAIL',
};
