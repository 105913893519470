import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { reducers } from '@resources/reducers';

import { cardsSaga } from '@resources/cards/saga';
import { usersSaga } from '@resources/users/saga';
import { pricesSaga } from '@resources/prices/saga';
import { configSaga } from '@resources/config/saga';
import { orgsSaga } from '@resources/orgs/saga';

import { middlewareToastFail } from '@resources/middleware/toast-fail';

export const store = () => {
    const sagaMiddleware = createSagaMiddleware();

    const s = createStore(
        reducers,
        composeWithDevTools(applyMiddleware(middlewareToastFail, sagaMiddleware, thunk)),
    );

    sagaMiddleware.run(cardsSaga);
    sagaMiddleware.run(usersSaga);
    sagaMiddleware.run(pricesSaga);
    sagaMiddleware.run(configSaga);
    sagaMiddleware.run(orgsSaga);

    return s;
};
