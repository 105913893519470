export interface Price {
    id: number;
    name: string;
    name_short: string;
    price: number;
    docs: string[];
}

export const initialPrice: Price = {
    id: 0,
    name: '',
    name_short: '',
    price: 0,
    docs: [],
};
