export const CONFIG_CONST = {
    // --
    CONFIG_CONFIG_FETCH: 'CONFIG_CONFIG_FETCH',
    CONFIG_CONFIG_SUCCESS: 'CONFIG_CONFIG_SUCCESS',
    CONFIG_CONFIG_FAIL: 'CONFIG_CONFIG_FAIL',

    // --
    CONFIG_CONFIG_UPDATE_FETCH: 'CONFIG_CONFIG_UPDATE_FETCH',
    CONFIG_CONFIG_UPDATE_SUCCESS: 'CONFIG_CONFIG_UPDATE_SUCCESS',
    CONFIG_CONFIG_UPDATE_FAIL: 'CONFIG_CONFIG_UPDATE_FAIL',
}