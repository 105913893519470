import { actionFlow } from '@resources/helpers/redux';
import { CONFIG_CONST } from '@resources/config/constants';
import { repoConfig, repoConfigUpdate } from '@resources/config/repo';
import { ConfigUpdatePayload } from '@resources/config/interfaces';

export const config = () => (dispatch) =>
    actionFlow(dispatch)(
        CONFIG_CONST.CONFIG_CONFIG_FETCH,
        CONFIG_CONST.CONFIG_CONFIG_SUCCESS,
        CONFIG_CONST.CONFIG_CONFIG_FAIL,
        async () => await repoConfig(),
    );

export const configUpdate = (payload: ConfigUpdatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        CONFIG_CONST.CONFIG_CONFIG_UPDATE_FETCH,
        CONFIG_CONST.CONFIG_CONFIG_UPDATE_SUCCESS,
        CONFIG_CONST.CONFIG_CONFIG_UPDATE_FAIL,
        async () => await repoConfigUpdate(payload),
    );
