import { ActionLog } from '@resources/reports/_action-log';
import { GraphOfficeIncome, initialGraphIncome } from '@resources/reports/_graph';

export interface State {
    action_logs: ActionLog[];
    graph_offices_incomes: GraphOfficeIncome[];

    action_logs_ids: number[];
}

export const initialState: State = {
    action_logs: [],
    graph_offices_incomes: [],

    action_logs_ids: [],
};
