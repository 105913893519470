import { Action } from 'redux';

import { State, initialState } from '@resources/pagination/_state';
import { PagAction } from '@resources/pagination/_pag';
import { actionName, actionNamespace, ActionPayload } from '@resources/helpers/redux';

export function pagination(
    state: State,
    namespace: string,
    name: string,
    action: PagAction,
): State {
    return {
        ...state,
        [namespace]: {
            ...state[namespace],
            [name]: {
                ...state[namespace][name],
                ...action.pagination,
            },
        },
    };
}

export const paginationReducer = (
    state: State = initialState,
    action: ActionPayload<Action & PagAction>,
): State => {
    const { type } = action;

    if (type.match('SUCCESS') && action.payload.pagination) {
        return pagination(state, actionNamespace(type), actionName(type), action.payload);
    }

    return state;
};
