import { Request, RequestResponse } from '@resources/helpers/request';
import {
    PricesResp,
    PriceCreateReq,
    PriceUpdateReq,
    PriceUpdateResp,
    PriceRemoveReq,
    PriceRemoveResp,
} from '@resources/prices/interfaces';

export function repoPrices(): Promise<PricesResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/prices/')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoPriceCreate(payload: PriceCreateReq): Promise<PricesResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/prices/', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoPriceUpdate(payload: PriceUpdateReq): Promise<PriceUpdateResp> {
    return new Promise((resolve, reject) => {
        Request.put('/v1/prices/' + payload.id, payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoPriceRemove(payload: PriceRemoveReq): Promise<PriceRemoveResp> {
    return new Promise((resolve, reject) => {
        Request.remove('/v1/prices/' + payload.price_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
