export interface CardSuggest {
    id: number;
    unique_id: string;

    first_name: string;
    last_name: string;
    patronymic: string;

    born_date: string;
}

export const initialCardSuggest: CardSuggest = {
    id: 0,
    unique_id: '',

    first_name: '',
    last_name: '',
    patronymic: '',

    born_date: '',
};
