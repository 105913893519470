export const REPORTS_CONST = {
    // --
    REPORTS_ACTION_LOGS_FETCH: 'REPORTS_ACTION_LOGS_FETCH',
    REPORTS_ACTION_LOGS_SUCCESS: 'REPORTS_ACTION_LOGS_SUCCESS',
    REPORTS_ACTION_LOGS_FAIL: 'REPORTS_ACTION_LOGS_FAIL',

    // --
    REPORTS_GRAPH_OFFICES_INCOMES_FETCH: 'REPORTS_GRAPH_OFFICES_INCOMES_FETCH',
    REPORTS_GRAPH_OFFICES_INCOMES_SUCCESS: 'REPORTS_GRAPH_OFFICES_INCOMES_SUCCESS',
    REPORTS_GRAPH_OFFICES_INCOMES_FAIL: 'REPORTS_GRAPH_OFFICES_INCOMES_FAIL',
};
