import { ActionPayload } from '@resources/helpers/redux';
import { initialState, State } from '@resources/users/_state';
import { USERS_CONST } from '@resources/users/constants';
import {
    UserCreateResp,
    AuthLoginResp,
    UserProfileResp,
    UserRemoveResp,
    UserUpdateResp,
    UsersResp,
    LoginsResp,
} from '@resources/users/interfaces';
import { initialUser } from '@resources/users/_user';
import { applyArrayDefault, removeArrayItem, updateArrayItem } from '@resources/helpers/array';

const authLogout = (state: State): State => ({
    ...state,
    profile: { ...initialUser },
    is_authorized: false,
});

const profileSuccess = (state: State, action: ActionPayload<UserProfileResp>): State => ({
    ...state,
    profile: {
        ...initialUser,
        ...action.payload.user,
    },
});

const authLoginSuccess = (state: State, action: ActionPayload<AuthLoginResp>): State => ({
    ...state,
    profile: {
        ...initialUser,
        ...action.payload.user,
    },
    is_authorized: true,
});

const usersSuccess = (state: State, action: ActionPayload<UsersResp>): State => ({
    ...state,
    users: applyArrayDefault(action.payload.users, initialUser),
});

const userCreateSuccess = (state: State, action: ActionPayload<UserCreateResp>): State => ({
    ...state,
    users: [
        ...state.users,
        {
            ...initialUser,
            ...action.payload.user,
        },
    ],
});

const userUpdateSuccess = (state: State, action: ActionPayload<UserUpdateResp>): State => ({
    ...state,
    users: updateArrayItem(state.users, action.payload.user.id, (user) => ({
        ...user,
        ...action.payload.user,
    })),
});

const userRemoveSuccess = (state: State, action: ActionPayload<UserRemoveResp>): State => ({
    ...state,
    users: removeArrayItem(state.users, action.payload.user_id),
});

const loginsSuccess = (state: State, action: ActionPayload<LoginsResp>): State => ({
    ...state,
    logins: action.payload.logins,
    is_authorized: true,
});

const authorizedSet = (state: State, action: ActionPayload<boolean>): State => ({
    ...state,
    is_authorized: action.payload,
});

export const usersReducer = (state: State = initialState, action: ActionPayload<any>) => {
    switch (action.type) {
        case USERS_CONST.USERS_AUTH_LOGOUT:
            return authLogout(state);

        case USERS_CONST.USERS_AUTH_LOGIN_SUCCESS:
            return authLoginSuccess(state, action);

        case USERS_CONST.USERS_USER_PROFILE_SUCCESS:
            return profileSuccess(state, action);

        case USERS_CONST.USERS_USERS_SUCCESS:
            return usersSuccess(state, action);

        case USERS_CONST.USERS_USER_CREATE_SUCCESS:
            return userCreateSuccess(state, action);

        case USERS_CONST.USERS_USER_UPDATE_SUCCESS:
            return userUpdateSuccess(state, action);

        case USERS_CONST.USERS_USER_REMOVE_SUCCESS:
            return userRemoveSuccess(state, action);

        case USERS_CONST.USERS_LOGINS_SUCCESS:
            return loginsSuccess(state, action);

        case USERS_CONST.USERS_AUTHORIZED_SET:
            return authorizedSet(state, action);

        default:
            return state;
    }
};
