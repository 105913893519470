import { applyArrayDefault, updateArrayItem } from '@resources/helpers/array';
import { ActionPayload } from '@resources/helpers/redux';
import { State, initialState } from '@resources/orgs/_state';
import { initialOrg } from '@resources/orgs/_org';
import { ORGS_CONST } from '@resources/orgs/constants';
import { OrgCreateResp, OrgRemoveResp, OrgsResp, OrgUpdateResp } from '@resources/orgs/interfaces';

const orgsSuccess = (state: State, action: ActionPayload<OrgsResp>): State => ({
    ...state,
    orgs: applyArrayDefault(action.payload.orgs, initialOrg),
});

const orgCreateSuccess = (state: State, action: ActionPayload<OrgCreateResp>): State => ({
    ...state,
    orgs: [
        ...state.orgs,
        {
            ...initialOrg,
            ...action.payload.org,
        },
    ],
});

const orgUpdateSuccess = (state: State, action: ActionPayload<OrgUpdateResp>): State => ({
    ...state,
    orgs: updateArrayItem(state.orgs, action.payload.org.id, (org) => ({
        ...initialOrg,
        ...org,
        ...action.payload.org,
    })),
});

const orgRemoveSuccess = (state: State, action: ActionPayload<OrgRemoveResp>): State => ({
    ...state,
    orgs: state.orgs.filter((org) => org.id !== action.payload.org_id),
});

export const orgsReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    switch (action.type) {
        case ORGS_CONST.ORGS_ORGS_SUCCESS:
            return orgsSuccess(state, action);

        case ORGS_CONST.ORGS_ORG_CREATE_SUCCESS:
            return orgCreateSuccess(state, action);

        case ORGS_CONST.ORGS_ORG_UPDATE_SUCCESS:
            return orgUpdateSuccess(state, action);

        case ORGS_CONST.ORGS_ORG_REMOVE_SUCCESS:
            return orgRemoveSuccess(state, action);

        default:
            return state;
    }
};
