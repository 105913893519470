import { Toaster, Position } from '@blueprintjs/core';

import * as styles from './index.module.scss';

import { IS_BROWSER } from '@resources/helpers/ssr';

export const AppToaster = IS_BROWSER
    ? Toaster.create({
          className: styles.container,
          position: Position.BOTTOM,
          usePortal: true,
          maxToasts: 1,
      })
    : {
          show: () => {},
      };
