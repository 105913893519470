import { Card } from '@resources/cards/_card';
import { Visit } from '@resources/cards/_visit';
import { VisitPrice } from '@resources/cards/_visit-price';
import { CardSuggest } from '@resources/cards/_card-suggest';
import { Document } from '@resources/cards/_document';
import { AddressSuggest } from '@resources/cards/interfaces';

export interface State {
    cards: Card[];
    visits: Visit[];
    visits_prices: VisitPrice[];
    documents: Document[];
    cards_suggests: CardSuggest[];
    address_suggests: AddressSuggest[];
    issued_suggests: string[];

    cards_ids: number[];
    visits_ids: number[];
}

export const initialState: State = {
    cards: [],
    visits: [],
    visits_prices: [],
    documents: [],
    cards_suggests: [],
    address_suggests: [],
    issued_suggests: [],

    cards_ids: [],
    visits_ids: [],
};
