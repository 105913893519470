export const PRICES_CONST = {
    // --
    PRICES_PRICES_FETCH: 'PRICES_PRICES_FETCH',
    PRICES_PRICES_SUCCESS: 'PRICES_PRICES_SUCCESS',
    PRICES_PRICES_FAIL: 'PRICES_PRICES_FAIL',

    // --
    PRICES_PRICE_CREATE_FETCH: 'PRICES_PRICE_CREATE_FETCH',
    PRICES_PRICE_CREATE_SUCCESS: 'PRICES_PRICE_CREATE_SUCCESS',
    PRICES_PRICE_CREATE_FAIL: 'PRICES_PRICE_CREATE_FAIL',

    // --
    PRICES_PRICE_UPDATE_FETCH: 'PRICES_PRICE_UPDATE_FETCH',
    PRICES_PRICE_UPDATE_SUCCESS: 'PRICES_PRICE_UPDATE_SUCCESS',
    PRICES_PRICE_UPDATE_FAIL: 'PRICES_PRICE_UPDATE_FAIL',

    // --
    PRICES_PRICE_REMOVE_FETCH: 'PRICES_PRICE_REMOVE_FETCH',
    PRICES_PRICE_REMOVE_SUCCESS: 'PRICES_PRICE_REMOVE_SUCCESS',
    PRICES_PRICE_REMOVE_FAIL: 'PRICES_PRICE_REMOVE_FAIL',
};
