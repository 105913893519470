import { Request, RequestResponse } from '@resources/helpers/request';
import {
    OfficeSaveReq,
    OfficeSaveResp,
    OfficeRemoveReq,
    OfficeRemoveResp,
    OfficesResp,
} from '@resources/offices/interfaces';

export function repoOffices(): Promise<OfficesResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/offices/')
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoOfficeSave(payload: OfficeSaveReq): Promise<OfficeSaveResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/offices/', payload.office)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoOfficeRemove(payload: OfficeRemoveReq): Promise<OfficeRemoveResp> {
    return new Promise((resolve, reject) => {
        Request.remove('/v1/offices/' + payload.office_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
