import { takeEvery, call, select } from 'redux-saga/effects';
import cookie from 'js-cookie';

import { logins, userProfile, users as usersAction } from '@resources/users/actions';
import { putDispatch } from '@resources/helpers/saga';
import { USERS_CONST } from '@resources/users/constants';
import { ActionPayload } from '@resources/helpers/redux';
import { AuthLoginResp } from '@resources/users/interfaces';
import { selectProfile } from '@resources/users/selectors';
import { User, USER_ROLE } from '@resources/users/_user';

function* init() {
    yield putDispatch(logins());
    yield putDispatch(userProfile());
}

export function* usersSaga() {
    // --
    yield call(init);
    yield takeEvery(USERS_CONST.USERS_AUTH_LOGIN_SUCCESS, init);

    // --
    yield takeEvery(USERS_CONST.USERS_USER_PROFILE_SUCCESS, function* () {
        const profile: User = yield select(selectProfile);
        if (profile.role === USER_ROLE.ADMIN) {
            yield putDispatch(usersAction());
        }
    });

    // Сохраняем токен после входа
    yield takeEvery(
        USERS_CONST.USERS_AUTH_LOGIN_SUCCESS,
        function* (action: ActionPayload<AuthLoginResp>) {
            cookie.set('access_token', action.payload.access_token, {
                path: '/',
                expires: 90,
                domain: process.env.HOST,
            });
        },
    );

    // Удаляем токен после выхода
    yield takeEvery(USERS_CONST.USERS_AUTH_LOGOUT, function* () {
        cookie.remove('access_token');
    });
}
