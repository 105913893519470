import { ErrorResponse } from '@resources/helpers/error';

// Действие с полезной нагрузкой
export type ActionPayload<T> = {
    type: string;
    payload: T;
};

export type ActionPayloadError = {
    type: string;
    payload: ErrorResponse;
};

// Создатель действий
export function actionCreate<T>(type: string, payload?: T): ActionPayload<T> {
    return {
        type: type,
        payload: payload,
    };
}

export function actionFlow(dispatch: any, payload?: any) {
    return async (fetch: string, success: string, fail: string, fn: any) => {
        try {
            dispatch(actionCreate(fetch, payload));
            const response = await fn();
            dispatch(actionCreate(success, response));
        } catch (e) {
            dispatch(actionCreate(fail, e));
        }
    };
}

export function actionNamespace(action: string): string {
    let parts = action.split('_');
    return parts[0].toLowerCase();
}

export function actionName(action: string): string {
    let parts = action.split('_');
    if (parts.length >= 2) {
        return parts
            .slice(1, parts.length - 1)
            .join('_')
            .toLowerCase();
    }
    return '';
}

export function actionType(action: string): string {
    let parts = action.split('_');

    return parts[parts.length - 1].toLowerCase();
}