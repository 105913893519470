import { takeEvery, call } from 'redux-saga/effects';
import { putDispatch } from '@resources/helpers/saga';
import { orgs } from '@resources/orgs/actions';
import { USERS_CONST } from '@resources/users/constants';
import { offices } from '@resources/offices/actions';

function* init() {
    yield putDispatch(orgs());
    yield putDispatch(offices());
}

export function* orgsSaga() {
    yield call(init);

    yield takeEvery(USERS_CONST.USERS_AUTH_LOGIN_SUCCESS, init);
}
