import { initialState, State } from '@resources/cards/_state';
import { CARDS_CONST } from '@resources/cards/constants';
import { ActionPayload } from '@resources/helpers/redux';
import {
    AddressSuggestsResp,
    CardCreateResp,
    CardRemoveResp,
    CardResp,
    CardsResp,
    CardsSuggestsResp,
    CardUpdateResp,
    IssuedSuggestsResp,
    VisitsOrgsResp,
    VisitsResp,
} from '@resources/cards/interfaces';
import {
    applyArrayDefault,
    mergeArrays,
    mergeArraysByUuid,
    updateArrayItem,
} from '@resources/helpers/array';
import { initialCard } from '@resources/cards/_card';
import { initialVisit } from '@resources/cards/_visit';
import { initialVisitPrice } from '@resources/cards/_visit-price';
import { initialCardSuggest } from '@resources/cards/_card-suggest';
import { initialDocument } from '@resources/cards/_document';

const parseAddress = (value: any) => {
    if (typeof value === 'object') {
        return value;
    }

    if (typeof value === 'string') {
        if (value === '') {
            return null;
        }

        return JSON.parse(value);
    }

    return null;
};

const cardsSuccess = (state: State, action: ActionPayload<CardsResp>): State => ({
    ...state,
    cards: mergeArrays(state.cards, applyArrayDefault(action.payload.cards, initialCard)).map(
        (card) => ({
            ...card,
            address_data1: parseAddress(card.address_data1),
            address_data2: parseAddress(card.address_data2),
        }),
    ),
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
    visits_prices: mergeArrays(
        state.visits_prices,
        applyArrayDefault(action.payload.visits_prices, initialVisitPrice),
    ),
    documents: mergeArraysByUuid(
        state.documents,
        applyArrayDefault(action.payload.documents, initialDocument),
    ),
    cards_ids: action.payload.cards.map((card) => card.id),
});

const cardCreateSuccess = (state: State, action: ActionPayload<CardCreateResp>): State => ({
    ...state,
    cards: [
        ...state.cards,
        {
            ...initialCard,
            ...action.payload.card,
            address_data1: parseAddress(action.payload.card.address_data1),
            address_data2: parseAddress(action.payload.card.address_data2),
        },
    ],
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
    visits_prices: mergeArrays(
        state.visits_prices,
        applyArrayDefault(action.payload.visits_prices, initialVisitPrice),
    ),
});

const cardUpdateSuccess = (state: State, action: ActionPayload<CardUpdateResp>): State => ({
    ...state,
    cards: updateArrayItem(state.cards, action.payload.card.id, (card) => ({
        ...initialCard,
        ...card,
        ...action.payload.card,
        address_data1: parseAddress(action.payload.card.address_data1),
        address_data2: parseAddress(action.payload.card.address_data2),
    })),
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
    visits_prices: mergeArrays(
        state.visits_prices,
        applyArrayDefault(action.payload.visits_prices, initialVisitPrice),
    ),
});

const cardRemoveSuccess = (state: State, action: ActionPayload<CardRemoveResp>): State => ({
    ...state,
    cards: state.cards.filter((card) => card.id !== action.payload.card_id),
});

const visitsSuccess = (state: State, action: ActionPayload<VisitsResp>): State => ({
    ...state,
    cards: mergeArrays(
        state.cards,
        action.payload.visits_cards
            .map((visitCard) => {
                return {
                    ...initialCard,
                    ...visitCard,
                };
            })
            .map((card) => ({
                ...card,
                address_data1: parseAddress(card.address_data1),
                address_data2: parseAddress(card.address_data2),
            })),
    ),
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
    visits_prices: mergeArrays(
        state.visits_prices,
        applyArrayDefault(action.payload.visits_prices, initialVisitPrice),
    ),
    visits_ids: action.payload.visits.map((visit) => visit.id),
});

const cardsSuggestsSuccess = (state: State, action: ActionPayload<CardsSuggestsResp>): State => ({
    ...state,
    cards_suggests: applyArrayDefault(action.payload.cards_suggests, initialCardSuggest),
});

const addressSuggestsSuccess = (
    state: State,
    action: ActionPayload<AddressSuggestsResp>,
): State => ({
    ...state,
    address_suggests: action.payload.address_suggests,
});

const cardSuccess = (state: State, action: ActionPayload<CardResp>): State => ({
    ...state,
    cards: mergeArrays(state.cards, [
        {
            ...initialCard,
            ...action.payload.card,
            address_data1: parseAddress(action.payload.card.address_data1),
            address_data2: parseAddress(action.payload.card.address_data2),
        },
    ]),
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
    visits_prices: mergeArrays(
        state.visits_prices,
        applyArrayDefault(action.payload.visits_prices, initialVisitPrice),
    ),
});

const issuedSuggestsSuccess = (state: State, action: ActionPayload<IssuedSuggestsResp>): State => ({
    ...state,
    issued_suggests: action.payload.issued_suggests,
});

const visitsOrgsSuccess = (state: State, action: ActionPayload<VisitsOrgsResp>): State => ({
    ...state,
    cards: mergeArrays(
        state.cards,
        action.payload.visits_cards.map((visitCard) => ({ ...initialCard, ...visitCard })),
    ).map((card) => ({
        ...card,
        address_data1: parseAddress(card.address_data1),
        address_data2: parseAddress(card.address_data2),
    })),
    visits: mergeArrays(state.visits, applyArrayDefault(action.payload.visits, initialVisit)),
});

export const cardsReducer = (state: State = initialState, action: ActionPayload<any>) => {
    switch (action.type) {
        case CARDS_CONST.CARDS_CARDS_SUCCESS:
            return cardsSuccess(state, action);
        case CARDS_CONST.CARDS_CARD_CREATE_SUCCESS:
            return cardCreateSuccess(state, action);
        case CARDS_CONST.CARDS_CARD_UPDATE_SUCCESS:
            return cardUpdateSuccess(state, action);
        case CARDS_CONST.CARDS_CARD_REMOVE_SUCCESS:
            return cardRemoveSuccess(state, action);
        case CARDS_CONST.CARDS_VISITS_SUCCESS:
            return visitsSuccess(state, action);
        case CARDS_CONST.CARDS_CARDS_SUGGESTS_SUCCESS:
            return cardsSuggestsSuccess(state, action);
        case CARDS_CONST.CARDS_ADDRESS_SUGGESTS_SUCCESS:
            return addressSuggestsSuccess(state, action);
        case CARDS_CONST.CARDS_ISSUED_SUGGESTS_SUCCESS:
            return issuedSuggestsSuccess(state, action);
        case CARDS_CONST.CARDS_CARD_SUCCESS:
            return cardSuccess(state, action);
        case CARDS_CONST.CARDS_VISITS_ORGS_SUCCESS:
            return visitsOrgsSuccess(state, action);
        default:
            return state;
    }
};
