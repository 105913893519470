// --
export interface GraphIncomeAmounts {
    common: number[];
    card: number[];
    cash: number[];
}

export const initialGraphIncomeAmounts: GraphIncomeAmounts = {
    common: [],
    card: [],
    cash: [],
};

// --
export interface GraphIncome {
    labels: string[];
    amounts: GraphIncomeAmounts;
}

export interface GraphOfficeIncome {
    office_id: number;
    income: GraphIncome;
}

export const initialGraphIncome: GraphIncome = {
    labels: [],
    amounts: { ...initialGraphIncomeAmounts },
};

// --
export type GraphIncomeTotal = {
    common: number;
    card: number;
    cash: number;
};
