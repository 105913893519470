// --
export interface Org {
    id: number;
    name: string;
}

export const initialOrg: Org = {
    id: 0,
    name: '',
};
