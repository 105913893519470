import { createSelector } from 'reselect';

import { GlobalState } from '@resources/reducers';
import { initialUser, User, USER_ROLE } from '@resources/users/_user';

// --
export const selectProfile = (state: GlobalState): User => state.users.profile;

// --
export const selectUsers = (state: GlobalState): User[] => state.users.users;

// --
export const selectUsersViewer = (state: GlobalState): User[] =>
    state.users.users.filter((user) => user.role === USER_ROLE.VIEWER);

// --
export const selectUserById = (state: GlobalState, userId: number): User => {
    const user = selectUsers(state).find((user) => user.id === userId);
    return user ? user : { ...initialUser };
};

// --
export const selectLogins = (state: GlobalState): string[] => state.users.logins;

// --
export const selectIsAuthorized = (state: GlobalState): boolean => state.users.is_authorized;

// --
export const selectIsAdmin = (state: GlobalState): boolean =>
    selectProfile(state).role === USER_ROLE.ADMIN;
