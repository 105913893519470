import { Action, MiddlewareAPI } from 'redux';
import { Intent } from '@blueprintjs/core';

import { AppToaster } from '@components/ui/toaster';

import { ActionPayloadError } from '@resources/helpers/redux';
import { authorizedSet } from '@resources/users/actions';
import { HTTP_STATUS } from '@resources/helpers/request';

// Отключение вывода ошибок для этих действий
const EXCEPTIONS: string[] = [];

// Отображение сообщений ошибок в запросе
export const middlewareToastFail =
    (store: MiddlewareAPI) =>
    (next: (action: Action) => any) =>
    async (action: ActionPayloadError) => {
        if (
            action.type &&
            action.type.match(/FAIL/g) &&
            action.payload &&
            EXCEPTIONS.indexOf(action.type) === -1
        ) {
            if (action.payload.http_status === HTTP_STATUS.UNAUTHORIZED) {
                // Неавторизованный пользователь
                store.dispatch(authorizedSet(false));

                AppToaster.show({
                    message: 'Введите логин и пароль',
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            } else {
                // Уведомления об ошибке
                AppToaster.show({
                    message: action.payload.msg,
                    intent: Intent.DANGER,
                    icon: 'error',
                });
            }
        }

        return next(action);
    };
