import {
    AddressSuggestsReq,
    AddressSuggestsResp,
    CardCreateReq,
    CardCreateResp,
    CardRemovePayload,
    CardRemoveResp,
    CardReq,
    CardResp,
    CardsReq,
    CardsResp,
    CardsSuggestsReq,
    CardsSuggestsResp,
    CardUpdateReq,
    CardUpdateResp,
    IssuedSuggestsPayload,
    IssuedSuggestsResp,
    VisitsOrgsReq,
    VisitsOrgsResp,
    VisitsReq,
    VisitsResp,
} from '@resources/cards/interfaces';
import { Request, RequestResponse } from '@resources/helpers/request';

export function repoCards(payload: CardsReq): Promise<CardsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/cards/', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoCardCreate(payload: CardCreateReq): Promise<CardCreateResp> {
    return new Promise((resolve, reject) => {
        Request.post('/v1/cards/', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoCardUpdate(payload: CardUpdateReq): Promise<CardUpdateResp> {
    return new Promise((resolve, reject) => {
        Request.put('/v1/cards/' + payload.id, payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoCardRemove(payload: CardRemovePayload): Promise<CardRemoveResp> {
    return new Promise((resolve, reject) => {
        Request.remove('/v1/cards/' + payload.card_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoVisits(payload: VisitsReq): Promise<VisitsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/visits/', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoCardsSuggests(payload: CardsSuggestsReq): Promise<CardsSuggestsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/cards/suggests', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoAddressSuggests(payload: AddressSuggestsReq): Promise<AddressSuggestsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/address/suggests', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoCard(payload: CardReq): Promise<CardResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/cards/' + payload.card_id)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoIssuedSuggests(payload: IssuedSuggestsPayload): Promise<IssuedSuggestsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/cards/issued-suggests', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoVisitsOrgs(payload: VisitsOrgsReq): Promise<VisitsOrgsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/visits/orgs', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
