// Обновление элемента массива по id
export function updateArrayItem<T>(
    array: Array<T & { id: number }>,
    id: number,
    updateItemCallback: (item: T & { id: number }) => any,
): Array<any> {
    const updatedItems = array.map((item, index) => {
        if (item.id !== id) {
            return item;
        }
        const updatedItem = updateItemCallback(item);
        return updatedItem;
    });

    return updatedItems;
}

// Удаление из массива по id
export function removeArrayItem(array: Array<any>, itemId: number) {
    return array.filter((item, index) => item.id !== itemId);
}

// Удаление из массива по id
export function removeArrayItemByIndex(array: Array<any>, index: number) {
    return array.filter((item, i) => i !== index);
}

// Применить к объекту массива значения по умолчанию
export function applyArrayDefault<T>(array: Array<T>, defaultItem: any): Array<T> {
    return array.map((item, index) => {
        return {
            ...defaultItem,
            ...item,
        };
    });
}

// Объеденияет два массива по ID. Второй массив перезаписывает первый
export function mergeArrays<T>(a: Array<T & { id: number }>, b: Array<T & { id: number }>) {
    var reduced = a.filter((aitem) => !b.find((bitem) => aitem.id === bitem.id));
    return reduced.concat(b);
}

// Объеденияет два массива по UUID. Второй массив перезаписывает первый
export function mergeArraysByUuid<T>(
    a: Array<T & { uuid: string }>,
    b: Array<T & { uuid: string }>,
) {
    var reduced = a.filter((aitem) => !b.find((bitem) => aitem.uuid === bitem.uuid));
    return reduced.concat(b);
}

// Если есть то удаляет элемент а иначе добавляет
export function toogleArrayItem<T>(array: Array<T>, item: T): Array<T> {
    return array.indexOf(item) !== -1 ? array.filter((r) => r !== item) : [...array, item];
}

export function toogleArrayItemById<T>(
    array: Array<T & { id: number }>,
    item: T & { id: number },
): Array<T & { id: number }> {
    return array.find((p) => p.id === item.id)
        ? array.filter((r) => r.id !== item.id)
        : [...array, item];
}

// Добавляет если нету
export function addArrayItem<T>(array: Array<T>, item: T): Array<T> {
    return array.indexOf(item) !== -1 ? array : [...array, item];
}

// Проверяем существование в массиве
export function issetArrayItem<T>(array: Array<T>, item: T): boolean {
    return array.indexOf(item) !== -1;
}

// Меняет местами элементы массива по индексу
export function swapArrayElements<T>(arr: Array<T>, index1: number, index2: number): Array<T> {
    let temp = arr[index1];

    arr[index1] = arr[index2];
    arr[index2] = temp;

    return arr;
}
