import { UserCompact, initialUserCompact } from '@resources/users/_user-compact';
import { Visit } from '@resources/cards/_visit';
import { initialOrg, Org } from '@resources/orgs/_org';

export enum CARD_TYPE {
    RESEARCH = 'research',
    PATIENT = 'patient',
}

export enum FAMILY_STATUS {
    UNKNOWN = '',
    MARRIAGE = 'marriage',
    NOT_MARRIAGE = 'not_marriage',
}

export const CITIZENSHIP_LIST = [
    'Таджикистан',
    'Узбекистан',
    'Кыргызстан',
    'Азербайджан',
    'Китай',
    'Армения',
    'Казахстан',
    'Украина',
    'Беларусь',
];

export const FAMILY_STATUS_LABEL = {
    [FAMILY_STATUS.UNKNOWN]: 'Неизвестно',
    [FAMILY_STATUS.MARRIAGE]: 'В браке',
    [FAMILY_STATUS.NOT_MARRIAGE]: 'Не в браке',
};

export const STAY_PERIOD_YEARS = [1, 2, 3, 4, 5];
export const STAY_PERIOD_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export interface Card {
    id: number;
    unique_id: string;
    user_id: number;

    type: CARD_TYPE;

    first_name: string;
    last_name: string;
    patronymic: string;

    sex: boolean;
    phone: string;
    born_date: string;
    family_status: FAMILY_STATUS;
    org_id: number;

    document_type: string;
    document_series: string;
    document_number: string;
    document_released: string;
    document_date: string;
    citizenship: string;
    stay_period_years: number;
    stay_period_months: number;

    polis: string;
    snils: string;
    medical_insurance_org: string;

    signature: string;

    address1: string;
    address_data1: any;
    address2: string;
    address_data2: any;

    created_at: string;
    updated_at: string;

    _visits: Visit[];
    _user: UserCompact;
    _org: Org;
}

export const initialCard: Card = {
    id: 0,
    unique_id: '',
    user_id: 0,

    type: CARD_TYPE.RESEARCH,

    first_name: '',
    last_name: '',
    patronymic: '',

    sex: true,
    phone: '',
    born_date: '',
    family_status: FAMILY_STATUS.UNKNOWN,
    org_id: 0,

    document_type: 'passport',
    document_series: '',
    document_number: '',
    document_released: '',
    document_date: '',
    citizenship: '',
    stay_period_years: 0,
    stay_period_months: 0,

    polis: '',
    snils: '',
    medical_insurance_org: '',

    signature: '',

    address1: '',
    address_data1: {},
    address2: '',
    address_data2: {},

    created_at: '',
    updated_at: '',

    _visits: [],
    _user: { ...initialUserCompact },
    _org: { ...initialOrg },
};
