import { actionFlow } from '@resources/helpers/redux';
import { ORGS_CONST } from '@resources/orgs/constants';
import { OrgCreatePayload, OrgRemovePayload, OrgUpdatePayload } from '@resources/orgs/interfaces';
import { repoOrgCreate, repoOrgRemove, repoOrgs, repoOrgUpdate } from '@resources/orgs/repo';

export const orgs = () => (dispatch) =>
    actionFlow(dispatch)(
        ORGS_CONST.ORGS_ORGS_FETCH,
        ORGS_CONST.ORGS_ORGS_SUCCESS,
        ORGS_CONST.ORGS_ORGS_FAIL,
        async () => await repoOrgs(),
    );

export const orgCreate = (payload: OrgCreatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        ORGS_CONST.ORGS_ORG_CREATE_FETCH,
        ORGS_CONST.ORGS_ORG_CREATE_SUCCESS,
        ORGS_CONST.ORGS_ORG_CREATE_FAIL,
        async () => await repoOrgCreate(payload),
    );

export const orgUpdate = (payload: OrgUpdatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        ORGS_CONST.ORGS_ORG_UPDATE_FETCH,
        ORGS_CONST.ORGS_ORG_UPDATE_SUCCESS,
        ORGS_CONST.ORGS_ORG_UPDATE_FAIL,
        async () => await repoOrgUpdate(payload),
    );

export const orgRemove = (payload: OrgRemovePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        ORGS_CONST.ORGS_ORG_REMOVE_FETCH,
        ORGS_CONST.ORGS_ORG_REMOVE_SUCCESS,
        ORGS_CONST.ORGS_ORG_REMOVE_FAIL,
        async () => {
            await repoOrgRemove(payload);
            return { org_id: payload.org_id };
        },
    );
