import { applyArrayDefault, updateArrayItem } from '@resources/helpers/array';
import { ActionPayload } from '@resources/helpers/redux';
import { State, initialState } from '@resources/prices/_state';
import { initialPrice } from '@resources/prices/_price';
import { PRICES_CONST } from '@resources/prices/constants';
import {
    PriceCreateResp,
    PriceRemoveResp,
    PricesResp,
    PriceUpdateResp,
} from '@resources/prices/interfaces';

const pricesSuccess = (state: State, action: ActionPayload<PricesResp>): State => ({
    ...state,
    prices: applyArrayDefault(action.payload.prices, initialPrice),
});

const priceCreateSuccess = (state: State, action: ActionPayload<PriceCreateResp>): State => ({
    ...state,
    prices: [
        ...state.prices,
        {
            ...initialPrice,
            ...action.payload.price,
        },
    ],
});

const priceUpdateSuccess = (state: State, action: ActionPayload<PriceUpdateResp>): State => ({
    ...state,
    prices: updateArrayItem(state.prices, action.payload.price.id, (price) => ({
        ...initialPrice,
        ...action.payload.price,
    })),
});

const priceRemoveSuccess = (state: State, action: ActionPayload<PriceRemoveResp>): State => ({
    ...state,
    prices: state.prices.filter((price) => price.id !== action.payload.price_id),
});

export const pricesReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    switch (action.type) {
        case PRICES_CONST.PRICES_PRICES_SUCCESS:
            return pricesSuccess(state, action);

        case PRICES_CONST.PRICES_PRICE_CREATE_SUCCESS:
            return priceCreateSuccess(state, action);

        case PRICES_CONST.PRICES_PRICE_UPDATE_SUCCESS:
            return priceUpdateSuccess(state, action);

        case PRICES_CONST.PRICES_PRICE_REMOVE_SUCCESS:
            return priceRemoveSuccess(state, action);

        default:
            return state;
    }
};
