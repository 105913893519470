import { VisitPrice } from '@resources/cards/_visit-price';
import { VisitCard, initialVisitCard } from '@resources/cards/_visit-card';
import { UserCompact, initialUserCompact } from '@resources/users/_user-compact';
import { CAUSE } from '@resources/cards/_cause';
import { initialOrg, Org } from '@resources/orgs/_org';
import { DocTemplate } from '@resources/config/_doc-template';
import { initialOffice, Office } from '@resources/offices/_office';
import { Document, initialDocument } from '@resources/cards/_document';

export enum PAY_METHOD {
    CASH = 'cash',
    CARD = 'card',
    BILL = 'bill',
}

export const PAY_METHOD_LABEL = {
    [PAY_METHOD.CARD]: 'Карта',
    [PAY_METHOD.CASH]: 'Наличные',
    [PAY_METHOD.BILL]: 'По счету',
};

export interface Visit {
    id: number;

    card_id: number;
    user_id: number;

    created_at: string;
    total: number;

    pay_method: PAY_METHOD;
    causes: CAUSE[];
    docs_datetime: string;
    org_id: number;
    office_id: number;
    docs: string[];

    sms_send_at: string;
    sms_signature_at: string;
    sms_token: string;

    _prices: VisitPrice[];
    _card: VisitCard;
    _user: UserCompact;
    _org: Org;
    _docs_templates: DocTemplate[];
    _office: Office;
    _documents: Document[];
}

export const initialVisit: Visit = {
    id: 0,

    card_id: 0,
    user_id: 0,

    created_at: '',
    total: 0,

    pay_method: PAY_METHOD.CASH,
    causes: [],
    docs_datetime: '',
    org_id: 0,
    office_id: 0,
    docs: [],

    sms_send_at: '',
    sms_signature_at: '',
    sms_token: '',

    _prices: [],
    _card: { ...initialVisitCard },
    _user: { ...initialUserCompact },
    _org: { ...initialOrg },
    _office: { ...initialOffice },
    _docs_templates: [],
    _documents: [],
};
