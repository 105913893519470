import { CARD_TYPE } from '@resources/cards/_card';
import { DATE_PERIOD } from '@resources/reports/_period';

export interface FilAction<T> {
    filters: T;
}

// --
export interface CardsCardsFil {
    query: string;
    datetime: string;
    user_id: number;
    card_type: CARD_TYPE;
}
export const initialCardsCardsFil: CardsCardsFil = {
    query: '',
    datetime: '',
    user_id: 0,
    card_type: undefined,
};

// --
export interface CardsVisitsFil {
    datetime: string;
    office_id: number;
}
export const initialCardsVisitsFil: CardsVisitsFil = {
    datetime: '',
    office_id: 0,
};

// --
export interface ReportsGraphOfficesIncomesFil {
    datetime: string;
    period: DATE_PERIOD;
}
export const initialReportsGraphOfficesIncomesFil: ReportsGraphOfficesIncomesFil = {
    datetime: '',
    period: DATE_PERIOD.MONTH,
};
