export interface ActionState {
    fetch: number;
    success: number;
    fail: number;
    loading: boolean;
}

export const initialActionState: ActionState = {
    fetch: 0,
    success: 0,
    fail: 0,
    loading: false,
};