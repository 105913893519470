export enum USER_ROLE {
    ADMIN = 'admin',
    VIEWER = 'viewer',
}

export const USER_ROLE_LABEL = {
    [USER_ROLE.ADMIN]: 'Администратор',
    [USER_ROLE.VIEWER]: 'Сотрудник',
};

export const USER_SEX_VALUE = {
    [1]: 'man',
    [0]: 'woman',
};

export interface User {
    id: number;

    role: USER_ROLE;
    username: string;
    login: string;
    password: string;
    office_id: number;

    app_token: string;
    signature: string;

    created_at: string;
    updated_at: string;
}

export const initialUser: User = {
    id: 0,

    role: USER_ROLE.VIEWER,
    username: '',
    login: '',
    password: '',
    office_id: 0,

    app_token: '',
    signature: '',

    created_at: '',
    updated_at: '',
};
