import { takeEvery, call } from 'redux-saga/effects';
import { putDispatch } from '@resources/helpers/saga';
import { prices } from '@resources/prices/actions';
import { USERS_CONST } from '@resources/users/constants';

function* init() {
    yield putDispatch(prices());
}

export function* pricesSaga() {
    yield call(init);

    yield takeEvery(USERS_CONST.USERS_AUTH_LOGIN_SUCCESS, init);
}
