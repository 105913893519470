import cookie from 'js-cookie';

import { actionCreate, actionFlow } from '@resources/helpers/redux';
import { USERS_CONST } from '@resources/users/constants';
import {
    repoAuthLogin,
    repoLogins,
    repoUserCreate,
    repoUserProfile,
    repoUserRemove,
    repoUsers,
    repoUserUpdate,
} from '@resources/users/repo';
import {
    AuthLoginPayload,
    UserCreatePayload,
    UserRemovePayload,
    UserUpdatePayload,
} from '@resources/users/interfaces';

export const authLogout = () => actionCreate(USERS_CONST.USERS_AUTH_LOGOUT);

export const authLogin = (payload: AuthLoginPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        USERS_CONST.USERS_AUTH_LOGIN_FETCH,
        USERS_CONST.USERS_AUTH_LOGIN_SUCCESS,
        USERS_CONST.USERS_AUTH_LOGIN_FAIL,
        async () => await repoAuthLogin(payload),
    );

export const users = () => (dispatch) =>
    actionFlow(dispatch)(
        USERS_CONST.USERS_USERS_FETCH,
        USERS_CONST.USERS_USERS_SUCCESS,
        USERS_CONST.USERS_USERS_FAIL,
        async () => await repoUsers(),
    );

export const userProfile = () => (dispatch) =>
    actionFlow(dispatch)(
        USERS_CONST.USERS_USER_PROFILE_FETCH,
        USERS_CONST.USERS_USER_PROFILE_SUCCESS,
        USERS_CONST.USERS_USER_PROFILE_FAIL,
        async () => await repoUserProfile(),
    );

export const userCreate = (payload: UserCreatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        USERS_CONST.USERS_USER_CREATE_FETCH,
        USERS_CONST.USERS_USER_CREATE_SUCCESS,
        USERS_CONST.USERS_USER_CREATE_FAIL,
        async () => await repoUserCreate(payload),
    );

export const userUpdate = (payload: UserUpdatePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        USERS_CONST.USERS_USER_UPDATE_FETCH,
        USERS_CONST.USERS_USER_UPDATE_SUCCESS,
        USERS_CONST.USERS_USER_UPDATE_FAIL,
        async () => await repoUserUpdate(payload),
    );

export const userRemove = (payload: UserRemovePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        USERS_CONST.USERS_USER_REMOVE_FETCH,
        USERS_CONST.USERS_USER_REMOVE_SUCCESS,
        USERS_CONST.USERS_USER_REMOVE_FAIL,
        async () => {
            await repoUserRemove(payload);
            return { user_id: payload.user_id };
        },
    );

export const logins = () => (dispatch) =>
    actionFlow(dispatch)(
        USERS_CONST.USERS_LOGINS_FETCH,
        USERS_CONST.USERS_LOGINS_SUCCESS,
        USERS_CONST.USERS_LOGINS_FAIL,
        async () => await repoLogins(),
    );

export const authorizedSet = (authorized: boolean) =>
    actionCreate(USERS_CONST.USERS_AUTHORIZED_SET, authorized);
