import { DocTemplate } from '@resources/config/_doc-template';

export interface Config {
    cash_visits_hide: boolean;
    express_test_number: string;
    express_test_expire: string;
    docs_templates: DocTemplate[];
}

export const initialConfig: Config = {
    cash_visits_hide: false,
    express_test_number: '',
    express_test_expire: '',
    docs_templates: [],
};
