import { ActionState, initialActionState } from '@resources/actions/_action-state';

// --
export interface CardsActions {
    card: ActionState;
    cards: ActionState;
    card_create: ActionState;
    card_update: ActionState;
    card_remove: ActionState;
    visit_sms_sign: ActionState;
    visits: ActionState;
    cards_suggests: ActionState;
}

export const initialCardsActions: CardsActions = {
    card: { ...initialActionState },
    cards: { ...initialActionState },
    card_create: { ...initialActionState },
    card_update: { ...initialActionState },
    card_remove: { ...initialActionState },
    visit_sms_sign: { ...initialActionState },
    visits: { ...initialActionState },
    cards_suggests: { ...initialActionState },
};

// --
export interface PricesActions {
    prices: ActionState;
    price_create: ActionState;
    price_update: ActionState;
    price_remove: ActionState;
}

export const initialPricesActions: PricesActions = {
    prices: { ...initialActionState },
    price_create: { ...initialActionState },
    price_update: { ...initialActionState },
    price_remove: { ...initialActionState },
};

// --
export interface ReportsActions {
    action_logs: ActionState;
    income: ActionState;
}

export const initialReportsActions: ReportsActions = {
    action_logs: { ...initialActionState },
    income: { ...initialActionState },
};

// --
export interface UsersActions {
    auth_login: ActionState;
    users: ActionState;
    user_proflie: ActionState;
    user_create: ActionState;
    user_update: ActionState;
    user_remove: ActionState;
    logins: ActionState;
}

export const initialUsersActions: UsersActions = {
    auth_login: { ...initialActionState },
    users: { ...initialActionState },
    user_proflie: { ...initialActionState },
    user_create: { ...initialActionState },
    user_update: { ...initialActionState },
    user_remove: { ...initialActionState },
    logins: { ...initialActionState },
};

// --
export interface ConfigActions {
    config: ActionState;
    config_update: ActionState;
}

export const initialConfigActions: ConfigActions = {
    config: { ...initialActionState },
    config_update: { ...initialActionState },
};

// --
export interface OrgsActions {
    orgs: ActionState;
    org_create: ActionState;
    org_update: ActionState;
    org_remove: ActionState;
}

export const initialOrgsActions: OrgsActions = {
    orgs: { ...initialActionState },
    org_create: { ...initialActionState },
    org_update: { ...initialActionState },
    org_remove: { ...initialActionState },
};

// --
export interface State {
    cards: CardsActions;
    prices: PricesActions;
    users: UsersActions;
    reports: ReportsActions;
    config: ConfigActions;
    orgs: OrgsActions;
}

export const initialState: State = {
    cards: { ...initialCardsActions },
    prices: { ...initialPricesActions },
    users: { ...initialUsersActions },
    reports: { ...initialReportsActions },
    config: { ...initialConfigActions },
    orgs: { ...initialOrgsActions },
};
