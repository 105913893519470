import { Price } from '@resources/prices/_price';
import { User, initialUser } from '@resources/users/_user';

export interface State {
    profile: User;
    users: User[];
    prices: Price[];
    logins: string[];

    is_authorized: boolean;
}

export const initialState: State = {
    profile: { ...initialUser },
    users: [],
    prices: [],
    logins: [],

    is_authorized: true,
};
