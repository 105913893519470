import moment from 'moment-timezone';

import { initialState } from '@resources/actions/_state';
import { ActionPayload, actionName, actionNamespace, actionType } from '@resources/helpers/redux';
import { State } from '@resources/actions/_state';

export function set(
    state: State,
    namespace: string,
    name: string,
    type: string,
    loading: boolean,
): State {
    return {
        ...state,
        [namespace]: state[namespace]
            ? {
                  ...state[namespace],
                  [name]: {
                      ...state[namespace][name],
                      [type]: moment().unix(),
                      loading: loading,
                  },
              }
            : {
                  ...state[namespace],
              },
    };
}

export const actionsReducer = (state: State = initialState, action: ActionPayload<any>): State => {
    const { type } = action;

    if (type.match(/FETCH/g)) {
        return set(state, actionNamespace(type), actionName(type), actionType(type), true);
    }
    if (type.match(/SUCCESS/g) || type.match(/FAIL/g)) {
        return set(state, actionNamespace(type), actionName(type), actionType(type), false);
    }

    return state;
};
