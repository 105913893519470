export interface Pag {
    page: number;
    limit: number;
    count: number;
}

export const initialPag: Pag = {
    page: 0,
    limit: 0,
    count: 0,
};

// Action
export interface PagAction {
    pagination: Pag;
}
