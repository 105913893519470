import {
    CardsCardsFil,
    initialCardsCardsFil,
    CardsVisitsFil,
    initialCardsVisitsFil,
    ReportsGraphOfficesIncomesFil,
    initialReportsGraphOfficesIncomesFil,
} from '@resources/filters/_fil';

// --
export interface ReportsFilters {
    graph_offices_incomes: ReportsGraphOfficesIncomesFil;
}
export const initialReportsFilters: ReportsFilters = {
    graph_offices_incomes: { ...initialReportsGraphOfficesIncomesFil },
};

// --
export interface CardsFilters {
    cards: CardsCardsFil;
    visits: CardsVisitsFil;
}
export const initialCardsFilters: CardsFilters = {
    cards: { ...initialCardsCardsFil },
    visits: { ...initialCardsVisitsFil },
};

// --
export interface State {
    cards: CardsFilters;
    reports: ReportsFilters;
}
export const initialState: State = {
    cards: { ...initialCardsFilters },
    reports: { ...initialReportsFilters },
};
